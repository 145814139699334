import * as Sentry from '@sentry/react';
import { TFileWithMeta } from '@components/FileUpload/components/dropzone/types/TFileWithMeta';
import { EACCEPTED_AUDIO_FORMATS } from 'app/types/enums/EACCEPTED_AUDIO_FORMATS';
import { RepositoriesService } from 'app/API';

export type RepositoryAttachment = {
  id: string;
  fileName: string;
  fileSize: number;
  fileExtension: string;
  createdAt: string;
  updatedAt: string;
  weight: number;
  isAudio: boolean;
  audioDuration?: number;
};

export const handleChangeStatusHandler = (
  organizationSlug: string | null,
  repositoryId: string | null,
  allFiles: TFileWithMeta[],
  setIsAllFilesLoaded: (item: boolean) => void,
  filesCountRef: React.MutableRefObject<number>,
  setFilesCount: (item: number) => void,
  newFiles: TFileWithMeta[],
  setNewFiles: (item: TFileWithMeta[]) => void,
  call: (v: Promise<any>) => Promise<any>,
) => {
  return async (file: TFileWithMeta, status: string) => {
    // console.log(file.meta.name, 'status', status, 'uploadFilesList', uploadFilesList, 'allFiles', allFiles);

    const files = [...newFiles];
    let index = files.findIndex((f) => f.meta.name === file.meta.name);
    if (index === -1) {
      index = files.length;
    }
    files[index] = file;
    const notLoadedFiles = files.find((item) => item.meta.status === 'uploading');
    setIsAllFilesLoaded(!notLoadedFiles);

    const performReady = (f: TFileWithMeta) => {
      setFilesCount(filesCountRef.current + 1);
      f.upload();
      setNewFiles([...files]);
      return;
    };

    if (status === 'ready') {
      setFilesCount(filesCountRef.current + 1);
      file.upload();
      setNewFiles([...files]);
      return;
    }

    if (status === 'error_upload') {
      return;
    }

    if (status === 'error_analyzing') {
      return;
    }

    if (status === 'rejected_file_type') {
      return;
    }

    if (status === 'error_file_size') {
      return;
    }

    if (status === 'getting_upload_params') {
      return;
    }

    if (status === 'preparing' || status === 'started') {
      setNewFiles([...files]);
      return;
    }

    if (status === 'uploading') {
      files[index].meta = file.meta;
      setNewFiles([...files]);
      return;
    }

    if (status === 'done') {
      setFilesCount(filesCountRef.current - 1);
      const readyFiles = files.filter((item) => item.meta.status === 'ready');
      if (readyFiles.length > 0) {
        readyFiles.forEach((f) => performReady(f));
      }
      try {
        (async () => {
          const weight = allFiles.findIndex((f) => f?.meta?.name === file?.meta.name);

          const isAudio = EACCEPTED_AUDIO_FORMATS.includes('.' + file.meta.name.split('.').pop()?.toLowerCase());

          // let audioDuration = 0;

          // if (isAudio) {
          //   audioDuration = await fetchAudioDurationFromFileReader(file.file);
          // }
          const response = await call(
            RepositoriesService.createAttachments({
              id: repositoryId ?? '',
              requestBody: {
                repositoryId: repositoryId ?? '',
                fileName: file.meta.name,
                fileSize: file.file.size?.toString() || '0',
                weight,
                isAudio,
                audioDuration: 0,
                organizationSlug: organizationSlug ?? '',
              },
            }),
          );
          if (!response) {
            files[index].meta.duration = 0;
            files[index].meta.status = 'error_upload';
            setNewFiles([...files]);
            return;
          }
          files[index].meta.Id = response;
          files[index].meta.duration = undefined;
          files[index].meta.status = 'done';
          setNewFiles([...files]);
        })();
      } catch (e) {
        Sentry.captureMessage(`Error while uploading file for repository ${repositoryId}`, {
          extra: { file, e, repositoryId },
        });
        Sentry.captureException(e);
        console.error(e);
      }
    }
  };
};

export const mapAttachmentsToIFileWithMeta = (attachments: RepositoryAttachment[]): TFileWithMeta[] => {
  return attachments.map((attachment) => {
    return {
      meta: {
        Id: attachment.id,
        name: attachment.fileName,
        size: Number(attachment.fileSize),
        status: 'done',
      },
      file: null,
      restart: () => {},
      cancel: () => {},
    };
  }) as never[];
};
