import { useState } from 'react';

import { useAPI } from '@hooks/useAPI';
import { ApiKeysService } from 'app/API';
import { useAuthContext } from '@providers/AuthProvider';
import { Button } from '@components/form-elements/buttons/Button';
import { AbsoluteSpinner } from '@components/spinners/Spinner';
import { DatePicker } from '@components/form-elements/DatePicker';

import { DatePickerWrapper, Wrapper } from './styles';

type TProps = {
  onSuccess: () => void;
  onCancel: () => void;
};

export const CreateAPIKeyModal = ({ onSuccess, onCancel }: TProps) => {
  const { call } = useAPI();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { organization } = useAuthContext();

  const [formValues, setFormValues] = useState<{ expirationDate: Date | null }>({
    expirationDate: null,
  });

  const submit = async () => {
    if (!organization) {
      return;
    }
    setIsSubmitting(true);
    await call(
      ApiKeysService.create({
        oid: organization?.id ?? '',
        requestBody: { expirationDate: formValues.expirationDate ? formValues.expirationDate.toString() : undefined },
      }),
    );
    setIsSubmitting(false);
    onSuccess();
  };

  return (
    <Wrapper>
      {isSubmitting ? <AbsoluteSpinner overlay={true} /> : null}
      <h2>Create New API Key</h2>

      <div className="workspaceSettings-inputWrapper">
        <div className="inputContainer">
          <DatePickerWrapper>
            <DatePicker
              label="Expiration Date (optional)"
              minDate={new Date()}
              maxDate={new Date(new Date().getFullYear() + 3, new Date().getMonth(), new Date().getDate())}
              value={formValues.expirationDate}
              onChange={(date: Date | null) => setFormValues({ expirationDate: date ?? null })}
            />
          </DatePickerWrapper>
        </div>
      </div>
      <div className="buttonContainer">
        <Button variant="outlined" onClick={onCancel}>
          Cancel
        </Button>

        <Button disabled={isSubmitting} onClick={() => submit()}>
          Create
        </Button>
      </div>
    </Wrapper>
  );
};
