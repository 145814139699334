import { createContext, useContext, useEffect, useState } from 'react';

import { TPageProviderProps } from 'app/types/providers/TPageProviderProps';

import { useAPI } from '@hooks/useAPI';
import { TransactionCodeService } from 'app/API';
import { TPaginatedResponse } from 'app/types/API/TPaginatedResponse';
import { TTransactionCode } from 'app/types/entities/TTransactionCode';
import { useSearchParams } from 'react-router-dom';

type TState = {
  transactionCodes: TPaginatedResponse<TTransactionCode>;
  isLoading: boolean;
  setIsLoading: (v: boolean) => void;
  sortingField: string;
  setSortingField: (v: string) => void;
  sortingReverse: boolean;
  setSortingReverse: (v: boolean) => void;
  search: string;
  setSearch: (v: string) => void;
  pageSize: number;
  setPageSize: (v: number) => void;
  reloadPage: () => void;
};

const PageContext = createContext<TState>({
  transactionCodes: {
    data: [],
    count: 0,
  },
  isLoading: false,
  setIsLoading: () => null,
  sortingField: 'createdAt',
  setSortingField: () => null,
  sortingReverse: true,
  setSortingReverse: () => null,
  search: '',
  setSearch: () => null,
  pageSize: 25,
  setPageSize: () => null,
  reloadPage: () => null,
});

export const PageProvider = ({ children }: TPageProviderProps) => {
  const { call } = useAPI();
  const [searchParams] = useSearchParams();
  const queryPage = searchParams.get('page');
  const page = queryPage ? Number(queryPage) : 1;
  const [pageSize, setPageSize] = useState(25);
  const [sortingField, setSortingField] = useState('createdAt');
  const [sortingReverse, setSortingReverse] = useState(true);
  const [search, setSearch] = useState('');
  const [transactionCodes, setTransactionCodes] = useState<TPaginatedResponse<TTransactionCode>>({
    data: [],
    count: 0,
  });
  const [isLoading, setIsLoading] = useState(false);

  const reloadPage = async () => {
    setIsLoading(true);
    const transactionCodes = await call(
      TransactionCodeService.findAll({
        pageNo: page.toString(),
        pageSize: pageSize.toString(),
        orderBy: sortingField,
        orderType: sortingReverse ? 'desc' : 'asc',
        search,
      }),
    );
    setTransactionCodes(transactionCodes);
    setIsLoading(false);
  };

  useEffect(() => {
    reloadPage();
  }, [page, pageSize, sortingField, sortingReverse, search]);

  return (
    <PageContext.Provider
      value={{
        transactionCodes,
        isLoading,
        setIsLoading,
        sortingField,
        setSortingField,
        sortingReverse,
        setSortingReverse,
        search,
        setSearch,
        pageSize,
        setPageSize,
        reloadPage,
      }}
    >
      {children}
    </PageContext.Provider>
  );
};

export const usePageContext = () => useContext(PageContext);
