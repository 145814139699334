import { Button } from '@components/form-elements/buttons/Button';
import { SwitchWrapper } from '@pages/Admin/OrganizationUsersListPage/components/Content/styles/SwitchWrapper';
import { Switch } from '@components/form-elements/Switch';
import { CurrencyInput } from '@components/form-elements/CurrencyInput';
import { useState } from 'react';
import { useAPI } from '@hooks/useAPI';
import { BillingAccountService } from 'app/API';
import toast from 'react-hot-toast';
import { DefaultPaymentIcon } from '@components/icons/DefaultPaymentIcon';
import { TProps } from './types';
import { Wrapper } from './styles';

export const AutoTopUpSettingsModal = ({ onCancel, billingAccount, reloadData, paymentMethods }: TProps) => {
  const { call } = useAPI();
  const [lowLimitAmount, setLowLimitAmount] = useState(billingAccount.autoTopUpSettings?.lowLimitAmount || 0);
  const [rechargeAmount, setRechargeAmount] = useState(billingAccount.autoTopUpSettings?.rechargeAmount || 0);
  const [autoTopUpEnabled, setAutoTopUpEnabled] = useState(billingAccount.autoTopUpSettings?.enabled || false);

  const updateAutoTopUpSettings = async () => {
    call(
      BillingAccountService.setAutoTopUpSettings({
        baId: billingAccount.id,
        requestBody: { enabled: autoTopUpEnabled, lowLimitAmount, rechargeAmount },
      }),
      {
        onError: (message) => toast.error(message),
        onSuccess: () => {
          toast.success('Settings updated');
          onCancel();
          reloadData();
        },
      },
    );
  };

  return (
    <Wrapper className="flex flex-col items-center justify-center p-4">
      <h2 className="my-4">Auto Top Up Settings</h2>

      <div className="flex flex-col p-2">
        <div className="self-start flex">
          <SwitchWrapper>
            <Switch
              checked={autoTopUpEnabled}
              onChange={() => setAutoTopUpEnabled(!autoTopUpEnabled)}
              disabled={false}
            />
          </SwitchWrapper>
          <div className="ml-2 topUpText">Auto top up</div>
        </div>

        <div className="self-start flex descriptionText my-2">
          Automatically recharge your account using your default payment method if your balance runs below the listed
          amount.
        </div>

        <div className="self-start w-full">
          <div className="inputLabelText mb-2 mt-4">When balance falls below</div>
          <CurrencyInput
            error={rechargeAmount < lowLimitAmount}
            disabled={!autoTopUpEnabled}
            value={lowLimitAmount / 100}
            label="$"
            onChange={(value) => setLowLimitAmount(value * 100 || 0)}
          />
        </div>

        <div className="self-start w-full">
          <div className="inputLabelText mb-2 mt-4">Automatically recharge</div>
          <CurrencyInput
            error={rechargeAmount < lowLimitAmount}
            disabled={!autoTopUpEnabled}
            value={rechargeAmount / 100}
            label="$"
            onChange={(value) => setRechargeAmount(value * 100 || 0)}
          />
        </div>

        <div className="self-start w-full">
          <div className="mb-2 mt-4 topUpText">Payment via:</div>

          <div className="ml-2 flex" key={paymentMethods.default.id}>
            <div className="mr-2">
              <DefaultPaymentIcon />
            </div>
            **** {paymentMethods.default.last4}
          </div>
        </div>

        {rechargeAmount < lowLimitAmount ? (
          <div className="error-text self-start text-red-500">Recharge amount should be greater than low limit</div>
        ) : null}

        <div className="note-text self-start">* Note: the minimum recharge amount cannot be less than $10.00</div>
      </div>

      <div className="buttonWrapper w-full max-w-[70%] self-end">
        <Button onClick={() => onCancel()} variant="outlined">
          Cancel
        </Button>
        <Button onClick={updateAutoTopUpSettings} disabled={rechargeAmount < lowLimitAmount}>
          Save
        </Button>
      </div>
    </Wrapper>
  );
};
