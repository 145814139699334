import { useState } from 'react';
import styled from 'styled-components';

import { useAuthContext } from '@providers/AuthProvider';

import { Button } from '@components/form-elements/buttons/Button';
import { DatePicker } from '@components/form-elements/DatePicker';
import { AbsoluteSpinner } from '@components/spinners/Spinner';
import { TJob } from 'app/types/entities/TJob';
import { JobsService } from 'app/API';
import { useAPI } from '@hooks/useAPI';

const Wrapper = styled.div`
  background: #ffffff;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  position: relative;
  padding: 30px;
  text-align: left;
  width: 270px;

  h2 {
    font-weight: 600;
    font-size: 26px;
    line-height: 130%;
    letter-spacing: 0.01em;
    color: #40608f;
    margin: 0;
  }

  .buttonGroup {
    display: flex;
    gap: 10px;
    justify-content: flex-start;
    margin-top: 20px;

    button {
      width: 130px;
    }
  }
`;

const DatePickerWrapper = styled.div`
  padding: 20px 0 12px;

  input {
    padding: 6px 10px 6px 40px;
  }

  .icon {
    right: auto;
    left: 15px;
  }
`;

type TProps = {
  job: TJob;
  onCancel: () => void;
  onSuccess: () => void;
};

export const EditDeadlineModal = ({ job, onCancel, onSuccess }: TProps) => {
  const { call } = useAPI();
  const { workspace } = useAuthContext();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [deadline, setDeadline] = useState<string | Date | null>(job.deadline ?? '');

  const workspaceId = job.workspaceId ?? job.workspace?.id ?? workspace?.id ?? '';

  const handleSubmit = async () => {
    setIsSubmitting(true);
    await call(
      JobsService.updateDeadline({
        wsid: workspaceId,
        id: job.id,
        requestBody: { deadline: new Date(deadline ?? '').toISOString() },
      }),
    );
    setIsSubmitting(false);
    onSuccess();
  };

  return (
    <Wrapper>
      {isSubmitting ? <AbsoluteSpinner overlay={true} /> : null}
      <h2>Edit Deadline</h2>
      <DatePickerWrapper>
        <DatePicker
          label="Deadline"
          dateFormat="MM/dd/yyyy"
          minDate={new Date()}
          maxDate={new Date(Date.now() + 365 * 24 * 60 * 60 * 1000)}
          value={deadline ? new Date(deadline) : null}
          onChange={setDeadline}
        />
      </DatePickerWrapper>

      <div className="buttonGroup">
        <Button variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <Button onClick={handleSubmit}>Save</Button>
      </div>
    </Wrapper>
  );
};
