import styled from 'styled-components';

export const Wrapper = styled.div`
  background: #ffffff;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  position: relative;
  padding: 30px;
  text-align: left;
  width: 780px;

  @media (max-height: 850px) {
    max-height: 90vh;
    overflow: auto;
  }

  h2 {
    color: #858dbd;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    margin: 0;
    padding: 0 0 30px;
    text-align: center;
  }

  .holiday-wrapper {
    padding: 0 0 30px;
    .image {
      width: 100%;
    }
    .description {
      font-size: 14px;
      font-weight: 600;
      line-height: 21px;
      text-align: center;
      color: #40608f;
    }
  }

  .input-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .label {
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
      color: #858dbd;
      padding: 0 10px 0 0;
    }

    .input,
    .textarea {
      margin: 0 0 20px;
    }
  }

  .card {
    color: #00122d;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    padding: 0 0 20px;

    &.red {
      color: red;
    }
  }

  .rates {
    display: flex;
    gap: 5px;

    .rate {
      border-radius: 14px;
      padding: 2px;

      &.active {
        background: linear-gradient(to right, #7aabf3, #2765be);
      }

      &.current .current {
        display: flex;
      }

      .current {
        display: none;
        width: 130px;
        padding: 2px 4px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 10px 10px 0 0;
        color: #ffffff;
        background: #40608f;
        top: 0;
        left: 0;
        position: absolute;
        text-transform: uppercase;

        text-align: center;
        font-size: 9px;
        font-style: normal;
        font-weight: 600;
        line-height: 160%; /* 14.4px */
      }

      .rate-white {
        border-radius: 12px;
        background: #ffffff;
        padding: 3px;
      }

      //&.current .rate-inner {
      //  padding-top: 10px;
      //}

      .rate-inner {
        border-radius: 10px;
        background: #f8fafb;
        display: flex;
        flex-direction: column;
        width: 130px;
        height: 110px;
        padding: 0 10px;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
      }

      .freelancer {
        background: #f1f5fb;
      }

      .pro {
        background: #e4ebf5;
      }

      .agency {
        background: #d5def2;
      }

      .business {
        background: #c4cee6;
      }

      .rate-name {
        color: #40608f;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%; /* 24px */
        text-transform: uppercase;
      }

      .rate-requirements,
      .rate-price {
        color: #40608f;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;

        &.striked {
          text-decoration: line-through;
        }

        &.promo {
          color: #ff2f2f;
          font-weight: 700;
        }
      }
    }
  }

  .range-wrapper {
    position: relative;

    .only-x-left {
      position: absolute;
      z-index: 200;
      left: 0;
      top: -30px;
      color: #40608f;
      text-align: center;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%; /* 15.6px */
      padding: 5px;
      border-radius: 5px;
      width: 162px;
      transform: translateX(-73px);
      background-color: #ffffff;
      box-shadow: 0 8px 10px rgba(0, 0, 0, 0.15);

      &::after {
        content: ' ';
        position: absolute;
        margin-left: -8px;
        border-width: 8px;
        border-style: solid;
        border-color: #fff transparent transparent;
        top: 41px;
        left: 50%;
        z-index: 10000;
      }
    }
  }

  .range {
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    cursor: pointer;
    height: 4px;
    margin: 30px 0 10px;
    width: 100%;
    outline: none;

    &:disabled {
      filter: grayscale(1);
      opacity: 0.3;
      cursor: not-allowed;
    }

    &:focus {
      outline: none;
    }

    &::-ms-track {
      width: 100%;
      height: 4px;
      cursor: pointer;
      transition: all ease 200ms;
      background: #e4ebf5;
      border-radius: 2px;
    }

    &::-webkit-slider-runnable-track {
      width: 100%;
      height: 4px;
      cursor: pointer;
      transition: all ease 200ms;
      background: #e4ebf5;
      border-radius: 2px;
    }

    &::-moz-range-track {
      width: 100%;
      height: 4px;
      cursor: pointer;
      transition: all ease 200ms;
      background: #e4ebf5;
      border-radius: 2px;
    }

    &::-ms-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 12px;
      height: 12px;
      flex-shrink: 0;
      cursor: pointer;
      box-shadow: 0 0 2px #eee;
      border: 3px solid #ffffff;
      border-radius: 12px;
      background: #2765be;
      background: linear-gradient(90deg, #7aabf3 0%, #2765be 100%);
    }

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 18px;
      height: 18px;
      flex-shrink: 0;
      cursor: pointer;
      box-shadow: 0 0 2px #eee;
      border: 3px solid #ffffff;
      border-radius: 12px;
      background: #2765be;
      background: linear-gradient(90deg, #7aabf3 0%, #2765be 100%);
    }

    &::-moz-range-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 12px;
      height: 12px;
      flex-shrink: 0;
      cursor: pointer;
      box-shadow: 0 0 2px #eee;
      border: 3px solid #ffffff;
      border-radius: 12px;
      background: #2765be;
      background: linear-gradient(90deg, #7aabf3 0%, #2765be 100%);
    }

    &::-webkit-slider-thumb {
      margin-top: -7px;
    }

    &::-ms-thumb {
      margin-top: 1px;
    }

    &::-moz-range-progress {
      border-radius: 2px;
      background: linear-gradient(90deg, #7aabf3 0%, #2765be 100%);
    }

    &::-webkit-progress-value {
      border-radius: 2px;
      background: linear-gradient(90deg, #7aabf3 0%, #2765be 100%);
    }

    &::-ms-fill-lower {
      background: #e4ebf5;
      border: 0 solid #010101;
      border-radius: 6px;
      box-shadow: 0 0 0 #000000;
    }

    &::-ms-fill-upper {
      background: #e4ebf5;
      border: 0 solid #010101;
      border-radius: 6px;
      box-shadow: 0 0 0 #000000;
    }

    &:focus::-ms-fill-lower {
      background: #e4ebf5;
    }

    &:focus::-ms-fill-upper {
      background: #e4ebf5;
    }
  }

  .amount-line {
    display: flex;
    margin: 20px 0 0;
    gap: 13px;

    .balance {
      color: #00122d;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%; /* 24px */
      padding: 2px 0 0;

      .striked {
        text-decoration: line-through;
      }

      .promo {
        color: #ff2f2f;
        font-weight: 700;
      }
    }

    .input-wrapper {
      width: 136px;
      &:first-child {
        width: 300px;
      }

      .label {
        white-space: nowrap;
      }

      input {
        height: 30px;
      }
    }

    .new-rate {
      .label {
        font-weight: 700;
      }
    }
  }

  .description {
    color: #878787;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 15.6px */
    padding: 0 0 10px;

    &.red {
      color: #ff2f2f;
      font-weight: 500;
    }

    &.special {
      color: #40608f;
      font-weight: 500;
    }

    p {
      padding: 0 0 2px 8px;
      margin: 0;

      &:first-child {
        padding-left: 0;
      }
    }
  }

  .buttonGroup {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    margin-top: 20px;

    button {
      width: 130px;
    }
  }
`;
