import styled from 'styled-components';

export const UnlockChoiceOption = styled.div`
  display: flex;
  background: #f1f5fb;
  width: 398px;
  height: 95px;
  padding: 10px;
  gap: 0px;
  border-radius: 5px 0px 0px 0px;
  justify-content: space-between;
  align-items: center;
  opacity: 0px;
  cursor: pointer;

  .title {
    text-align: left;
    color: #858dbd;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 12px */
    padding: 0 0 10px;
  }

  .price {
    font-family: General Sans;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
  }

  .description {
    font-family: General Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }
`;
