import { createContext, useContext, useEffect, useState } from 'react';

import { TPageProviderProps } from 'app/types/providers/TPageProviderProps';
import { TPaginatedResponse } from 'app/types/API/TPaginatedResponse';

import { AdminService } from 'app/API';
import { useModalContext } from '@providers/ModalProvider';
import { ModalNamesEnum } from '@constants/enums/ModalNamesEnum';
import { useAPI } from '@hooks/useAPI';
import { TSubscriptionProduct } from 'app/types/entities/TSubscriptionProduct';
import { TOrganization } from 'app/types/entities/TOrganization';
import { useSearchParams } from 'react-router-dom';

type TState = {
  plans: TSubscriptionProduct[];
  organizations: TPaginatedResponse<TOrganization>;
  isLoading: boolean;
  setIsLoading: (v: boolean) => void;
  sortingField: string;
  setSortingField: (v: string) => void;
  sortingReverse: boolean;
  setSortingReverse: (v: boolean) => void;
  search: string;
  setSearch: (v: string) => void;
  pageSize: number;
  setPageSize: (v: number) => void;
  reloadPage: () => void;
  openBalanceModal: (e: React.MouseEvent<SVGElement>, billingAccountId: string) => void;
  openRateModal: (e: React.MouseEvent<SVGElement>, billingAccountId: string) => void;
  switchASOne: (organizationId: string, value: boolean) => void;
};

const PageContext = createContext<TState>({
  plans: [],
  organizations: { data: [], count: 0 },
  isLoading: false,
  setIsLoading: () => null,
  sortingField: 'createdAt',
  setSortingField: () => null,
  sortingReverse: true,
  setSortingReverse: () => null,
  search: '',
  setSearch: () => null,
  pageSize: 25,
  setPageSize: () => null,
  reloadPage: () => null,
  openBalanceModal: () => {},
  openRateModal: () => {},
  switchASOne: () => {},
});

export const PageProvider = ({ children }: TPageProviderProps) => {
  const { call } = useAPI();
  const { openModal } = useModalContext();
  const [searchParams] = useSearchParams();
  const queryPage = searchParams.get('page');
  const page = queryPage ? Number(queryPage) : 1;
  const [search, setSearch] = useState('');
  const [plans, setPlans] = useState<TSubscriptionProduct[]>([]);
  const [sortingField, setSortingField] = useState('createdAt');
  const [sortingReverse, setSortingReverse] = useState(true);
  const [pageSize, setPageSize] = useState(25);
  const [organizations, setOrganizations] = useState<TPaginatedResponse<TOrganization>>({
    data: [],
    count: 0,
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    reloadPage();
  }, [page, pageSize, sortingField, sortingReverse, search]);

  const reloadPage = async () => {
    setIsLoading(true);
    const plans = await call(AdminService.getAllAsOnePlans());
    setPlans(plans);
    const organizations = await call(
      AdminService.getOrganizationsList({
        pageNo: page.toString(),
        pageSize: pageSize.toString(),
        orderBy: sortingField,
        orderType: sortingReverse ? 'desc' : 'asc',
        search,
      }),
    );
    setOrganizations(organizations);
    setIsLoading(false);
  };

  const openBalanceModal = (e: React.MouseEvent<SVGElement>, billingAccountId: string) => {
    e.stopPropagation();
    openModal(ModalNamesEnum.AdminAddBalanceToBillingAccount, {
      billingAccountId,
      onSuccess: () => {
        reloadPage();
      },
    });
  };

  const openRateModal = (e: React.MouseEvent<SVGElement>, billingAccountId: string) => {
    e.stopPropagation();
    openModal(ModalNamesEnum.AdminBillingAccountRate, {
      billingAccountId,
      onSuccess: () => {
        reloadPage();
      },
    });
  };

  const switchASOne = async (organizationId: string, value: boolean) => {
    setIsLoading(true);
    await call(AdminService.changeAsOneAllowed({ oid: organizationId, requestBody: { value } }));
    reloadPage();
  };

  return (
    <PageContext.Provider
      value={{
        plans,
        organizations,
        isLoading,
        setIsLoading,
        sortingField,
        setSortingField,
        sortingReverse,
        setSortingReverse,
        search,
        setSearch,
        pageSize,
        setPageSize,
        reloadPage,
        openBalanceModal,
        openRateModal,
        switchASOne,
      }}
    >
      {children}
    </PageContext.Provider>
  );
};

export const usePageContext = () => useContext(PageContext);
