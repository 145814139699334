import { useEffect, useState } from 'react';
import { Wrapper } from './styles/Wrapper';
import { useAPI } from '@hooks/useAPI';
import { PromptsService } from 'app/API';
import { Select } from '@components/form-elements/Select';
import { EditPrompt } from './components/EditPromptStage';
import { PreviewPrompt } from './components/SecondStage';

export const Content = () => {
  const [stage, setStage] = useState('edit');
  const [promptsSlugs, setPromptsSlugs] = useState([]);
  const [chosenPromptSlug, setChosenPromptSlug] = useState('');
  const [chosenPrompt, setChosenPrompt] = useState({ template: '', version: 0, variables: '' });
  const [updatedTemplate, setUpdatedTemplate] = useState('');
  const [reload, setReload] = useState(false);

  const manualReload = () => {
    setStage('edit');
    setReload(!reload);
  };

  const { call } = useAPI();

  useEffect(() => {
    call(PromptsService.getAll(), {
      onSuccess: (prompts) => {
        setPromptsSlugs(prompts?.map((prompt: any) => prompt?.slug));
      },
    });
  }, [reload]);

  useEffect(() => {
    if (chosenPromptSlug) {
      call(PromptsService.getPrompt({ slug: chosenPromptSlug }), {
        onSuccess: (prompt) => {
          setChosenPrompt(prompt);
          setUpdatedTemplate(prompt.template);
          setStage('edit');
        },
      });
    }
  }, [chosenPromptSlug, reload]);

  return (
    <Wrapper>
      <div>
        {promptsSlugs?.length && (
          <div className="mb-2">
            <Select
              options={promptsSlugs.map((slug) => ({ label: slug, value: slug }))}
              label={'Existing prompts'}
              onChange={(name, value) => setChosenPromptSlug(value)}
              value={chosenPromptSlug}
            />
          </div>
        )}

        {!!chosenPrompt?.version && (
          <div className="mb-2 ml-2 p-2 version">Version :{chosenPrompt.version.toFixed(2)}</div>
        )}

        {!!chosenPrompt?.variables.length && <div className="mb-2 ml-2 p-2 variables">Required variables :</div>}

        {!!chosenPrompt?.variables.length && (
          <div className="flex">
            {chosenPrompt?.variables?.split('|').map((variable) => {
              return (
                <div className="mb-4 ml-2 p-2 variables flex flex-row border rounded-xl bg-gray-100" key={variable}>
                  {variable}
                </div>
              );
            })}
          </div>
        )}
      </div>

      {stage === 'edit' ? (
        <EditPrompt
          template={chosenPrompt.template}
          updatedTemplate={updatedTemplate}
          setTemplate={setUpdatedTemplate}
          setStage={setStage}
        />
      ) : null}

      {stage === 'preview' ? (
        <PreviewPrompt
          setStage={setStage}
          left={chosenPrompt.template}
          right={updatedTemplate}
          slug={chosenPromptSlug}
          manualReload={manualReload}
        />
      ) : null}
    </Wrapper>
  );
};
