import { useState } from 'react';

import { useAPI } from '@hooks/useAPI';
import { SubscriptionsService } from 'app/API';
import { ModalNamesEnum } from '@constants/enums/ModalNamesEnum';
import { useModalContext } from '@providers/ModalProvider';

import { Button } from '@components/form-elements/buttons/Button';
import { AbsoluteSpinner } from '@components/spinners/Spinner';

import { TProps } from './types/TProps';
import { Wrapper } from './styles/Wrapper';
import { RangeSliderWrapper } from './styles/RangeSliderWrapper';

export const AdminConfirmSubscription = ({
  billingAccountId,
  subscriptionProductId,
  planName,
  price,
  freeSeats,
  onCancel,
  onSuccess,
}: TProps) => {
  const { call } = useAPI();
  const { openModal, closeModal } = useModalContext();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [numberOfLicenses, setNumberOfLicenses] = useState(1);

  const innerSubmit = async () => {
    setIsSubmitting(true);
    const result = await call(
      SubscriptionsService.adminCreate({
        baId: billingAccountId,
        requestBody: { subscriptionProductId: subscriptionProductId, licenseCount: numberOfLicenses },
      }),
    );
    if (!result || !result.success) {
      setIsSubmitting(false);
      let message = 'Something went wrong. Please contact administrator.';
      if (result.error === 'No payment methods found') {
        message = 'Cannot create subscription. Please add a payment method first';
      }
      if (result.error === 'Subscription already exists') {
        message = 'You already have this subscription. Please choose another or modify an existing subscription';
      }
      onCancel();
      openModal(ModalNamesEnum.GenericErrorModal, {
        message,
      });
      return;
    }
    closeModal();
    onSuccess();
  };

  return (
    <Wrapper>
      {isSubmitting ? <AbsoluteSpinner overlay={true} /> : null}
      <h2 data-testid="purchasePlan">Confirm</h2>
      <p className="description">
        You are about to purchase the &quot;{planName}&quot; plan for ${price.toFixed(2)} per license per month +
        applicable sales tax
      </p>

      <RangeSliderWrapper>
        <label htmlFor="steps-range" className="block mb-2 font-[600] text-xs text-primary text-left">
          Number of licenses:
        </label>
        <input
          id="steps-range"
          type="range"
          min={1}
          max={50}
          value={numberOfLicenses}
          step={1}
          onChange={(e) => setNumberOfLicenses(Number(e.target.value))}
          className="w-full h-2 bg-[#E4EBF5] rounded-lg appearance-none cursor-pointer"
        />
        <span className="text-xs">
          {numberOfLicenses} license{numberOfLicenses > 1 && 's'}
        </span>
      </RangeSliderWrapper>

      <div className="text-left my-4 flex flex-col">
        <div>
          <span className="font-[600] text-xs text-primary">Free licenses in subscription plan:</span>
          <span className="float-right">{freeSeats}</span>
        </div>
        <div>
          <span className="font-[600] text-xs text-primary">Total subscription price per month:</span>
          <span className="float-right">
            ${numberOfLicenses <= freeSeats ? 0 : (Number(price) * (numberOfLicenses - freeSeats)).toFixed(2)}
          </span>
        </div>
      </div>

      <div className="buttonContainer">
        <Button type="button" variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          data-testid="submitButton"
          type="submit"
          onClick={() => innerSubmit()}
          variant="contained"
          disabled={isSubmitting}
        >
          Purchase
        </Button>
      </div>
    </Wrapper>
  );
};
