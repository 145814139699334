import { Button } from '@components/form-elements/buttons/Button';
import { Textarea } from '@components/form-elements/Textarea';
import { TProps } from './types/Props';

export const EditPrompt = ({ template, setTemplate, setStage, updatedTemplate }: TProps) => {
  return (
    <div className="first-stage">
      <div className="flex-block">
        <div className="left-wrapper h-full">
          <Textarea value={updatedTemplate} onChange={(e) => setTemplate(e.target.value)} label="" />
        </div>
      </div>
      <Button disabled={template === updatedTemplate} onClick={() => setStage('preview')}>
        Preview
      </Button>
    </div>
  );
};
