import { createContext, useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { TPageProviderProps } from 'app/types/providers/TPageProviderProps';
import { getAmericanTime } from '@helpers/getAmericanTimezone';
import { downloadCSV } from '@helpers/downloadCSV';
import { useAPI } from '@hooks/useAPI';
import { TPaginatedResponse } from 'app/types/API/TPaginatedResponse';
import { TUser } from 'app/types/entities/TUser';

import { AdminService } from 'app/API';
import { useNavigate } from 'react-router';

type TState = {
  users: TPaginatedResponse<TUser>;
  isLoading: boolean;
  setSortingField: (v: string) => void;
  sortingReverse: boolean;
  setSortingReverse: (v: boolean) => void;
  search: string;
  setSearch: (v: string) => void;
  pageSize: number;
  setPageSize: (v: number) => void;
  editId: string;
  setEditId: (v: string) => void;
  reloadPage: () => void;
  setIsLoading: (v: boolean) => void;
  sortingField: string;
  exportCSV: () => void;
  editPermission: (uid: string, role: string, value: boolean) => void;
  deleteUser: (uid: string) => void;
};

const PageContext = createContext<TState>({
  users: { data: [], count: 0 },
  isLoading: false,
  setSortingField: () => null,
  sortingReverse: true,
  setSortingReverse: () => null,
  search: '',
  setSearch: () => null,
  pageSize: 25,
  setPageSize: () => null,
  editId: '',
  setEditId: () => null,
  reloadPage: () => null,
  setIsLoading: () => null,
  sortingField: 'createdAt',
  exportCSV: () => {},
  editPermission: () => {},
  deleteUser: () => {},
});

export const PageProvider = ({ children }: TPageProviderProps) => {
  const { call } = useAPI();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const queryPage = searchParams.get('page');
  const page = queryPage ? Number(queryPage) : 1;
  const [isLoading, setIsLoading] = useState(false);
  const [sortingField, setSortingField] = useState('createdAt');
  const [sortingReverse, setSortingReverse] = useState(true);
  const [search, setSearch] = useState('');
  const [pageSize, setPageSize] = useState(25);
  const [reload, setReload] = useState(false);
  const [users, setUsers] = useState<TPaginatedResponse<TUser>>({
    data: [],
    count: 0,
  });
  const [editId, setEditId] = useState('');

  const setPage = (page: number) => {
    navigate(`?page=${page}`);
  };

  const reloadPage = () => {
    setPage(1);
    setReload(!reload);
  };

  useEffect(() => {
    setIsLoading(true);
    call(
      AdminService.getAdmins({
        pageNo: page.toString(),
        pageSize: pageSize.toString(),
        orderBy: sortingField,
        orderType: sortingReverse ? 'desc' : 'asc',
        search,
      }),
    ).then((res) => {
      setUsers(res);
      setIsLoading(false);
    });
  }, [page, pageSize, reload, sortingField, sortingReverse, search]);

  const exportCSV = async () => {
    const exportData = await call(
      AdminService.getAdmins({
        pageNo: '1',
        pageSize: users.count.toString(),
        orderBy: 'createdAt',
        orderType: 'desc',
        search,
      }),
    );
    if (!exportData) {
      return;
    }
    const headers = ['ID', 'Created', 'Name', 'Email', 'Phone', 'Organizations'];
    const array = exportData.data.map((data: TUser) => {
      return {
        id: data.id,
        created: getAmericanTime(data.createdAt),
        name: `${data.name} ${data.lastname}`,
        email: data.email,
        phone: data.phone,
        organizations: (data.organizations || []).map((o) => o.name).join(' / '),
      };
    });
    return downloadCSV(array, headers);
  };

  const editPermission = async (uid: string, role: string, value: boolean) => {
    setIsLoading(true);
    await call(AdminService.toggleAdminRole({ uid, requestBody: { role, value } }));
    reloadPage();
  };

  const deleteUser = async (uid: string) => {
    setIsLoading(true);
    await call(AdminService.toggleAdminRole({ uid, requestBody: { role: 'ROLE_BILLING_ADMIN', value: false } }));
    await call(AdminService.toggleAdminRole({ uid, requestBody: { role: 'ROLE_ORG_ADMIN', value: false } }));
    await call(AdminService.toggleAdminRole({ uid, requestBody: { role: 'ROLE_VS_ADMIN', value: false } }));
    await call(AdminService.toggleAdminRole({ uid, requestBody: { role: 'ROLE_SUPER_ADMIN', value: false } }));
    reloadPage();
  };

  return (
    <PageContext.Provider
      value={{
        users,
        isLoading,
        setSortingField,
        sortingReverse,
        setSortingReverse,
        search,
        setSearch,
        pageSize,
        setPageSize,
        editId,
        setEditId,
        reloadPage,
        setIsLoading,
        sortingField,
        exportCSV,
        editPermission,
        deleteUser,
      }}
    >
      {children}
    </PageContext.Provider>
  );
};

export const usePageContext = () => useContext(PageContext);
