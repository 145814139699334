import React from 'react';
import styled from 'styled-components';

import { useAuthContext } from '@providers/AuthProvider';

import { Button } from '@components/form-elements/buttons/Button';
import { Input } from '@components/form-elements/Input';

import { CrossIcon } from '@pages/User/NewJobPage/components/Content/blocks/icons/CrossIcon';
import { TJob } from 'app/types/entities/TJob';
import { JobsService } from 'app/API';
import { useAPI } from '@hooks/useAPI';

const ModalWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  position: relative;
  padding: 30px;
  text-align: left;
  width: 487px;

  h2 {
    font-weight: 600;
    font-size: 26px;
    line-height: 130%;
    letter-spacing: 0.01em;
    color: #40608f;
    margin: 0 0 20px;
  }

  .assignedTo {
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    color: #858dbd;
    padding: 20px 0 10px;
  }

  .text {
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    color: #858dbd;
  }

  .radioWrapper {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #000000;
    height: 24px;
  }

  .customTable {
    height: 250px;
    overflow-y: auto;
    overflow-x: hidden;

    tr {
      cursor: pointer;
    }

    thead {
      tr {
        cursor: default;

        td,
        th {
          height: auto;
          padding: 10px 0;
        }
      }
    }

    tbody {
      td {
        padding: 10px 0;
      }
    }
  }

  .buttonGroup {
    display: flex;
    gap: 10px;
    justify-content: flex-start;
    margin-top: 20px;

    button {
      width: 130px;
    }
  }

  .assignTo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 20px;

    svg {
      cursor: pointer;
    }

    .assignToText {
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      color: #00122d;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

const Tags = styled.div`
  padding: 20px 0 0;
  display: flex;
  flex-wrap: wrap;

  .tag {
    position: relative;
    padding: 4px 25px 4px 6px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #858dbd;
    background: #f1f5fb;
    border-radius: 5px;
    margin: 0 5px 5px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 150px;

    svg {
      position: absolute;
      right: 8px;
      top: 5px;
    }
  }
`;
const SmallWhiteInput = styled(Input)`
  input {
    background: #ffffff;
    padding: 0 10px;
    height: 30px;
  }
`;

type TProps = {
  job: TJob;
  onCancel: () => void;
  onSuccess: () => void;
};

export const EditJobTagsModal = ({ job, onCancel, onSuccess }: TProps) => {
  const { call } = useAPI();
  const { workspace } = useAuthContext();
  const [tags, setTags] = React.useState(job.tags ?? []);

  const handleSubmit = async () => {
    const workspaceId = job.workspaceId ?? job.workspace?.id ?? workspace?.id ?? '';
    await call(
      JobsService.editJobTags({
        wsid: workspaceId,
        id: job.id,
        requestBody: { tags: tags.join(',').replace(/\s/g, '_').split(',') },
      }),
    );
    onSuccess();
  };

  const onChange = async (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = evt.target;
    setTags(value.split(','));
  };

  const deleteTag = async (tag: string) => {
    setTags(tags.filter((t: string) => t !== tag));
  };

  const tagsValue = (tags ?? []).join(',').replace(/\s/g, '_');
  const tagsArray = (tags ?? []).filter((tag: string) => !!tag);

  return (
    <ModalWrapper>
      <h2>Edit Job Tags</h2>

      <SmallWhiteInput
        name="tags"
        label="Add tags..."
        value={tagsValue}
        disabled={!job}
        onChange={onChange}
        required={false}
      />
      {tagsArray.length ? (
        <Tags>
          {tagsArray.map((tag: string, index: number) => (
            <button className="tag" key={index} onClick={() => deleteTag(tag)}>
              {tag.replace(/\s/g, '_')}
              <CrossIcon />
            </button>
          ))}
        </Tags>
      ) : null}

      <div className="buttonGroup">
        <Button variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <Button onClick={handleSubmit}>Save</Button>
      </div>
    </ModalWrapper>
  );
};
