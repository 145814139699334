import styled from 'styled-components';

export const RangeSliderWrapper = styled.div`
  input[type='range'] {
    height: 4px;
    -webkit-appearance: none;
    margin: 10px 0;
    width: 100%;
  }

  input[type='range']:focus {
    outline: none;
  }

  input[type='range']::-webkit-slider-runnable-track {
    width: 100%;
    height: 6px;
    cursor: pointer;
    box-shadow: 0 0 0 #000000;
    background: #e4ebf5;
    border-radius: 3px;
    border: 0 solid #010101;
  }

  input[type='range']::-webkit-slider-thumb {
    box-shadow: 0 0 2px #454545;
    border: 4px solid #ffffff;
    height: 19px;
    width: 19px;
    border-radius: 19px;
    background: #b4b9d9;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -6px;
  }

  input[type='range']:focus::-webkit-slider-runnable-track {
    background: #e4ebf5;
  }

  input[type='range']::-moz-range-track {
    width: 100%;
    height: 6px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0 0 0 #000000;
    background: #e4ebf5;
    border-radius: 3px;
    border: 0 solid #010101;
  }

  input[type='range']::-moz-range-thumb {
    box-shadow: 0 0 2px #454545;
    border: 4px solid #ffffff;
    height: 19px;
    width: 19px;
    border-radius: 19px;
    background: #b4b9d9;
    cursor: pointer;
  }

  input[type='range']::-ms-track {
    width: 100%;
    height: 6px;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }

  input[type='range']::-ms-fill-lower {
    background: #e4ebf5;
    border: 0 solid #010101;
    border-radius: 6px;
    box-shadow: 0 0 0 #000000;
  }

  input[type='range']::-ms-fill-upper {
    background: #e4ebf5;
    border: 0 solid #010101;
    border-radius: 6px;
    box-shadow: 0 0 0 #000000;
  }

  input[type='range']::-ms-thumb {
    margin-top: 1px;
    box-shadow: 0 0 2px #454545;
    border: 4px solid #ffffff;
    height: 19px;
    width: 19px;
    border-radius: 19px;
    background: #b4b9d9;
    cursor: pointer;
  }

  input[type='range']:focus::-ms-fill-lower {
    background: #e4ebf5;
  }

  input[type='range']:focus::-ms-fill-upper {
    background: #e4ebf5;
  }
`;
