import { LinearProgressLoader } from '@components/spinners/LinearProgressLoader';
import { Wrapper } from '@pages/Admin/DashboardPage/components/Content/styles/Wrapper';
import { usePageContext } from '@pages/Admin/DashboardPage/providers/PageProvider';
import { ModalNamesEnum } from '@constants/enums/ModalNamesEnum';
import { useModalContext } from '@providers/ModalProvider';
import { useAPI } from '@hooks/useAPI';
import { AdminService } from '../../../../../API';

export const Content = () => {
  const { openModal, closeModal } = useModalContext();
  const { call } = useAPI();
  const { isLoading, dashboard } = usePageContext();

  const openNotifyModal = () => {
    openModal(ModalNamesEnum.GenericConfirmation, {
      title: 'Confirm',
      description: 'Do you really want to notify all online users?',
      buttonText: 'Notify',
      onSuccess: async () => {
        call(AdminService.notifyAboutNewVersion());
        closeModal();
      },
    });
  };

  return (
    <Wrapper>
      <LinearProgressLoader active={isLoading} />

      <div className="flex-block">
        <div className="left-wrapper">
          <div className="line">
            <div className="card">
              <div className="title">Users online</div>
              <div className="subTitle">{dashboard?.onlineUsers ?? 0} Total</div>
              <div className="notify">
                <button onClick={openNotifyModal}>Notify to update page</button>
              </div>
            </div>
            <div className="card">
              <div className="title">Organizations</div>
              <div className="subTitle">{dashboard?.organizationsCount ?? 0} Total</div>
            </div>
            <div className="card">
              <div className="title">Workspaces</div>
              <div className="subTitle">{dashboard?.workspacesCount ?? 0} Total</div>
            </div>
            <div className="card">
              <div className="title">Users</div>
              <div className="subTitle">{dashboard?.usersCount ?? 0} Total</div>
            </div>
            <div className="card">
              <div className="title">Transactions</div>
              <div className="subTitle">{dashboard?.transactionsCount ?? 0} Total</div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
