import { convertDuration } from '@helpers/convertDuration';
import { StyledSelect } from '@components/Modal/PriceModal/styles/StyledSelect';

export function PriceModalNoPriceDescription({
  price,
  model,
  setModel,
  organizationCanUseModel2,
}: {
  price: { price: number; ASRRate: string; duration?: number };
  model: string;
  setModel: (v: string) => void;
  organizationCanUseModel2: boolean;
}) {
  return (
    <div>
      {organizationCanUseModel2 ? (
        <>
          <StyledSelect
            options={[
              { value: 'model-1', label: 'Model 1' },
              { value: 'model-2', label: 'Model 2' },
            ]}
            label="Transcription model"
            name="model"
            value={model}
            onChange={(_, value) => setModel(value)}
          />
        </>
      ) : null}

      <p className="description-text">
        <span>After submitting this job you will be charged</span>
      </p>
      <br style={{ display: 'block', margin: '10px 0 0' }} />

      <div className="dflex">
        <span>Duration:</span>
        {convertDuration(price?.duration ?? 0)}
      </div>
    </div>
  );
}
