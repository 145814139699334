import { useEffect, useRef } from 'react';

import { TProps } from './types/TProps';
import { Wrapper } from './styles/Wrapper';
import { StyledSelect } from './styles/StyledSelect';
import { StyledText } from './styles/StyledText';
import { Pager } from './components/pager';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

export const PagePagination = ({ totalItemsCount, pageSize, setPageSize }: TProps) => {
  const totalPages = Math.ceil((totalItemsCount || 1) / pageSize);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const queryPage = searchParams.get('page');
  const page = queryPage ? Number(queryPage) : 1;

  const initialRender = useRef(true);
  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }
    navigate(`?page=1`);
  }, [pageSize]);

  const getShowingPagesText = () => {
    const start = ((page ?? 1) - 1) * pageSize + 1;
    const end = Math.min(start + pageSize - 1, totalItemsCount);
    return `Showing ${start} - ${end} of ${totalItemsCount}`;
  };

  return (
    <Wrapper className="flex items-center fixed bottom-0 z-10 justify-between bg-alabaster">
      {totalPages ? (
        <>
          <div className="dflex">
            <div className="flex align-middle pb-2">
              <span className="text-primary text-sm tracking-wide block self-center">Rows per page</span>
              <StyledSelect
                name="rowsPerPage"
                className="px-2"
                label={String(pageSize)}
                onChange={(e, value) => setPageSize(+value)}
                options={[
                  { value: 25, label: '25' },
                  { value: 50, label: '50' },
                  { value: 100, label: '100' },
                ]}
              />
              <StyledText className="pl-3 self-center">{getShowingPagesText()}</StyledText>
            </div>
          </div>

          <div className="paginationBlock">
            <Pager
              currentPage={page}
              pagesCount={totalPages}
              onChange={(page: number) => {
                navigate(`?page=${page}`);
              }}
            />
          </div>
        </>
      ) : null}
    </Wrapper>
  );
};
