import styled from 'styled-components';

export const MainContent = styled.main`
  padding: 8px 30px 70px;
  min-height: calc(100vh - 120px);

  td {
    vertical-align: top;
  }
`;
