import { Switch } from '@components/form-elements/Switch';

import { TProps } from './types/TProps';
import { EditCellContent } from './styles/EditCellContent';
import { usePageContext } from '@pages/Admin/OrganizationsListPage/providers/PageProvider';

export const InlineEditRow = ({ data }: TProps) => {
  const { editId, editOrganizationFeatureFlag } = usePageContext();

  if (data.id !== editId || !data.featureFlags) {
    return null;
  }
  if (!data.featureFlags) {
    data.featureFlags = {};
  }

  return (
    <tr>
      <td colSpan={8}>
        <EditCellContent>
          <div className="feature-flags-head">Feature Flags</div>
          <div className="feature-flags-list">
            {Object.keys(data.featureFlags).map((flagName) => {
              if (!data.featureFlags) {
                return null;
              }
              const value = data.featureFlags[flagName];
              return (
                <div key={`${data.id}-${flagName}`} className="feature-flag">
                  <Switch
                    className="switch"
                    checked={value}
                    onChange={() => editOrganizationFeatureFlag(data.id, flagName, !value)}
                  />
                  <div className="label">{flagName}</div>
                </div>
              );
            })}
          </div>
        </EditCellContent>
      </td>
    </tr>
  );
};
