import styled from 'styled-components';

import { Divider } from '@components/Divider';
import { useModalContext } from '@providers/ModalProvider';
import { ModalNamesEnum } from '@constants/enums/ModalNamesEnum';
import { TBillingAccount } from 'app/types/entities/TBillingAccount';

const Wrapper = styled.div`
  width: 210px;
  height: 200px;
  background: transparent;
  border-radius: 10px;
  border: 1px solid #d5def2;
  padding: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  cursor: pointer;

  :hover {
    border: 1px solid #858dbd;
  }

  .divider {
    margin: 10px 0;
  }

  .title {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    text-transform: capitalize;
    color: #858dbd;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .text {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    color: #878787;
  }
`;

type TProps = {
  billingAccount: TBillingAccount;
  onSuccess?: () => void;
};

type PlanSelection = {
  subscriptionProductId: string;
  planName: string;
  price: number;
  freeSeats: number;
};

export const AddSubscription = ({ billingAccount, onSuccess }: TProps) => {
  const { openModal, closeModal } = useModalContext();

  const onClick = () => {
    openModal(ModalNamesEnum.AdminChooseASOneSubscriptionModal, {
      billingAccountId: billingAccount.id,
      onSuccess: ({ subscriptionProductId, planName, price, freeSeats }: PlanSelection) => {
        closeModal();
        if (onSuccess) {
          onSuccess();

          openModal(ModalNamesEnum.AdminConfirmSubscription, {
            billingAccountId: billingAccount.id,
            subscriptionProductId,
            planName,
            freeSeats,
            onSuccess,
            price: Number(price),
          });
        }
      },
    });
  };

  return (
    <Wrapper onClick={onClick}>
      <div className="title">+ Add Subscription</div>
      <Divider className="divider" />
      <div className="text">Expand your access: add an additional subscription to your account.</div>
    </Wrapper>
  );
};
