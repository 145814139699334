import { Button } from '@components/form-elements/buttons/Button';

import { TProps } from './types/TProps';
import { Wrapper } from './styles/Wrapper';

export const GenericConfirmationModal = ({ title, description, buttonText, onCancel, onSuccess }: TProps) => (
  <Wrapper>
    <h2>{title}</h2>
    <p className="description">{description}</p>
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onSuccess();
      }}
    >
      <div className="buttonContainer">
        <Button type="button" variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <Button type="submit" variant="contained">
          {buttonText}
        </Button>
      </div>
    </form>
  </Wrapper>
);
