import styled from 'styled-components';

export const EditCellContent = styled.div`
  margin: 0;
  padding: 20px 0;
  min-height: 88px;

  .feature-flags-head {
    color: #858dbd;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    padding: 0 0 22px;
  }

  .feature-flags-list {
    display: flex;
    align-items: center;
    gap: 30px;
    flex-wrap: wrap;

    .feature-flag {
      flex: 0 0 auto;
      display: flex;
      gap: 10px;
      align-items: center;
      max-width: 100%;

      .label {
        color: #858dbd;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 12px */
      }
    }
  }
`;
