import { TProps } from './types/TProps';
import { ModalNamesEnum } from '@constants/enums/ModalNamesEnum';
import { useModalContext } from '@providers/ModalProvider';
import { useAuthContext } from '@providers/AuthProvider';

export const PaymentMethod = ({ isLoading, defaultPaymentMethod }: TProps) => {
  const { openModal } = useModalContext();
  const { organization } = useAuthContext();

  const billingAccount = organization?.billingAccount;

  const addNewPaymentMethod = () => {
    openModal(ModalNamesEnum.AddPaymentMethod, {
      billingAccountId: billingAccount?.id,
      onSuccess: () => {
        openModal(ModalNamesEnum.TopUpCreditBalance, {
          billingAccountId: organization?.billingAccount?.id,
        });
      },
    });
  };

  if (isLoading) {
    return <div className="card empty">Loading...</div>;
  }
  if (!defaultPaymentMethod) {
    return (
      <div className="card empty red">
        <button className="red" onClick={addNewPaymentMethod}>
          Not set
        </button>
      </div>
    );
  }
  return <div className="card">Card: **** {defaultPaymentMethod.last4}</div>;
};
