import { Helmet } from 'react-helmet-async';

import { Header } from '@components/Header';
import { TrashBin } from '@components/icons/TrashBin';
import { CustomTable } from '@components/Table';
import { LinearProgressLoader } from '@components/spinners/LinearProgressLoader';
import { Done } from '@components/icons/Done';
import { Edit as EditIcon } from '@components/icons/Edit';

import { usePageContext } from '../../providers/PageProvider';
import { FeatureFlag } from '../../types/FeatureFlag';

import { Wrapper } from './styles/Wrapper';
import { CellContent } from './styles/CellContent';
import { DeleteButton } from './styles/DeleteButton';
import { EditButton } from './styles/EditButton';

export const Content = () => {
  const { removeFlag, editFlag, createFlag, flags, isLoading } = usePageContext();

  const tableHead = [
    {
      headComponent: () => <td>#</td>,
      render: (data: FeatureFlag, index: number) => <CellContent>{index + 1}</CellContent>,
    },
    {
      headComponent: () => <td>Name</td>,
      render: (data: FeatureFlag) => <CellContent>{data.name}</CellContent>,
    },
    {
      headComponent: () => <td>Value</td>,
      render: (data: FeatureFlag) => <CellContent>{data.value ? <Done width="24px" /> : ''}</CellContent>,
    },
    {
      headComponent: () => <td>Actions</td>,
      render: (data: FeatureFlag) => (
        <CellContent>
          <div className="flex gap-1">
            <EditButton title="Edit" onClick={() => editFlag(data)}>
              <EditIcon />
            </EditButton>
            <DeleteButton title="Remove" onClick={() => removeFlag(data.id)}>
              <TrashBin />
            </DeleteButton>
          </div>
        </CellContent>
      ),
    },
  ];

  return (
    <>
      <Helmet>
        <title>Feature Flags - AutoScript Admin</title>
      </Helmet>
      <Header title={'Feature Flags'} buttonLabel={'Create new flag'} buttonOnClick={() => createFlag()} />

      <LinearProgressLoader active={isLoading} />
      <Wrapper>
        <CustomTable head={tableHead} data={flags} />
      </Wrapper>
    </>
  );
};
