import React, { useState } from 'react';

import { FeatureFlagsService } from 'app/API';
import { useAPI } from '@hooks/useAPI';
import { useModalContext } from '@providers/ModalProvider';

import { Button } from '@components/form-elements/buttons/Button';
import { AbsoluteSpinner } from '@components/spinners/Spinner';
import { Input } from '@components/form-elements/Input';
import { Switch } from '@components/form-elements/Switch';

import { Wrapper } from './styles/Wrapper';
import { TProps } from './types/TProps';

export const EditFeatureFlag = ({ onCancel, onSuccess, featureFlag }: TProps) => {
  const { call } = useAPI();
  const { closeModal } = useModalContext();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [flag, setFlag] = useState(featureFlag);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    await call(FeatureFlagsService.update({ id: featureFlag.id, requestBody: { name: flag.name, value: flag.value } }));
    setIsSubmitting(false);
    closeModal();
    onSuccess();
  };

  return (
    <Wrapper>
      {isSubmitting ? <AbsoluteSpinner overlay={true} /> : null}
      <h2>Edit Feature Flag</h2>

      <div className="input-wrapper">
        <div className="label">Name</div>
        <Input
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFlag({ ...flag, name: e.target.value })}
          label="Name"
          value={flag.name}
        />
      </div>

      <div className="input-wrapper">
        <div className="label">Value</div>
        <Switch className="switch" checked={flag.value} onChange={() => setFlag({ ...flag, value: !flag.value })} />
      </div>

      <div className="buttonGroup">
        <Button variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <Button onClick={handleSubmit}>Save</Button>
      </div>
    </Wrapper>
  );
};
