import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer-continued';

import { TProps } from './types/TProps';

export const Diff = ({ left, right, method = DiffMethod.WORDS_WITH_SPACE }: TProps) => {
  return (
    <ReactDiffViewer
      oldValue={left}
      newValue={right}
      splitView={true}
      showDiffOnly={true}
      useDarkTheme={false}
      compareMethod={method}
    />
  );
};
