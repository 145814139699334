import { DiffMethod } from 'react-diff-viewer-continued';
import { pluralize } from '@helpers/pluralize';
import { Button } from '@components/form-elements/buttons/Button';
import { Diff } from './components/Diff';
import { TProps } from './types/Props';
import { PromptsService } from 'app/API';
import { useAPI } from '@hooks/useAPI';
import toast from 'react-hot-toast';
import { EErrorMessages } from '@constants/errorMessages';

export const PreviewPrompt = ({ left, right, slug, manualReload, setStage }: TProps) => {
  const { call } = useAPI();
  const leftLines = left.split('\n').length ?? 0;
  const rightLines = right.split('\n').length ?? 0;

  return (
    <div className="second-stage">
      <div className="flex-block">
        <div className="left-wrapper">
          <div className="lines">
            {leftLines} {pluralize(leftLines, 'line', `lines`)}
          </div>
        </div>
        <div className="right-wrapper">
          <div className="lines">
            {rightLines} {pluralize(rightLines, 'line', `lines`)}
          </div>
        </div>
      </div>
      <div className="diff">
        <Diff left={left} right={right} method={DiffMethod.WORDS_WITH_SPACE} />
      </div>
      <div className="flex justify-center items-center">
        <Button
          onClick={() => {
            setStage('edit');
          }}
          className="mr-4"
        >
          Back
        </Button>
        <Button
          onClick={() => {
            call(PromptsService.updatePromptTemplate({ requestBody: { slug, template: right } }), {
              onSuccess: async () => {
                manualReload();
                toast.success('Updated successfully');
              },
              onError: async (message) => {
                manualReload();
                toast.error(message || EErrorMessages.DEFAULT);
              },
            });
          }}
        >
          Save changes
        </Button>
      </div>
    </div>
  );
};
