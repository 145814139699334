import { createContext, useContext, useEffect, useState } from 'react';

import { useModalContext } from '@providers/ModalProvider';
import { ModalNamesEnum } from '@constants/enums/ModalNamesEnum';
import { TPageProviderProps } from 'app/types/providers/TPageProviderProps';

import { FeatureFlagsService } from 'app/API';
import { useAPI } from '@hooks/useAPI';

import { FeatureFlag } from '../types/FeatureFlag';

type TState = {
  flags: FeatureFlag[];
  isLoading: boolean;
  setIsLoading: (v: boolean) => void;
  createFlag: () => void;
  editFlag: (flag: FeatureFlag) => void;
  removeFlag: (code: string) => Promise<void>;
};

const PageContext = createContext<TState>({
  flags: [],
  isLoading: false,
  setIsLoading: () => null,
  createFlag: () => null,
  editFlag: () => null,
  removeFlag: async () => {},
});

export const PageProvider = ({ children }: TPageProviderProps) => {
  const { call } = useAPI();
  const [flags, setFlags] = useState<FeatureFlag[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [reloadPageTrigger, setReloadPageTrigger] = useState(false);
  const { openModal, closeModal } = useModalContext();

  const load = () => {
    setIsLoading(true);
    call(FeatureFlagsService.findAll()).then((data) => {
      setFlags(data ?? []);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    load();
  }, [reloadPageTrigger]);

  const createFlag = () => {
    openModal(ModalNamesEnum.CreateNewFeatureFlag, {
      onSuccess: () => {
        closeModal();
        setReloadPageTrigger(!reloadPageTrigger);
      },
    });
  };

  const editFlag = (featureFlag: FeatureFlag) => {
    openModal(ModalNamesEnum.EditFeatureFlag, {
      featureFlag,
      onSuccess: () => {
        closeModal();
        setReloadPageTrigger(!reloadPageTrigger);
      },
    });
  };

  const removeFlag = async (id: string) => {
    const confirmation = window.confirm('Are you sure you want to remove this feature flag?');

    if (!confirmation) {
      return;
    }
    await call(FeatureFlagsService.remove({ id }));
    setReloadPageTrigger(!reloadPageTrigger);
  };

  return (
    <PageContext.Provider
      value={{
        flags,
        isLoading,
        setIsLoading,
        createFlag,
        editFlag,
        removeFlag,
      }}
    >
      {children}
    </PageContext.Provider>
  );
};

export const usePageContext = () => useContext(PageContext);
