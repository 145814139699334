import React from 'react';

export const InfoIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10.7998 16.4998C10.7998 16.1815 10.9262 15.8763 11.1513 15.6513C11.3763 15.4262 11.6815 15.2998 11.9998 15.2998C12.3181 15.2998 12.6233 15.4262 12.8483 15.6513C13.0734 15.8763 13.1998 16.1815 13.1998 16.4998C13.1998 16.8181 13.0734 17.1233 12.8483 17.3483C12.6233 17.5734 12.3181 17.6998 11.9998 17.6998C11.6815 17.6998 11.3763 17.5734 11.1513 17.3483C10.9262 17.1233 10.7998 16.8181 10.7998 16.4998Z"
        fill="#858DBD"
      />
      <path
        d="M11.2998 7.01311L11.3133 6.8611C11.3445 6.70454 11.4284 6.56321 11.5511 6.46071C11.677 6.35557 11.8358 6.29797 11.9998 6.29797C12.1638 6.29797 12.3226 6.35557 12.4485 6.46071C12.5712 6.56321 12.6551 6.70454 12.6863 6.8611L12.6998 7.01311V11.9865L12.6863 12.1385C12.6551 12.2951 12.5712 12.4364 12.4485 12.5389C12.3226 12.644 12.1638 12.7016 11.9998 12.7016C11.8358 12.7016 11.677 12.644 11.5511 12.5389C11.4284 12.4364 11.3445 12.2951 11.3133 12.1385L11.2998 11.9865V7.01311Z"
        fill="#858DBD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.6998 11.9998C21.6998 14.5724 20.6778 17.0396 18.8587 18.8587C17.0396 20.6778 14.5724 21.6998 11.9998 21.6998C9.42721 21.6998 6.95997 20.6778 5.14087 18.8587C3.32177 17.0396 2.2998 14.5724 2.2998 11.9998C2.2998 9.42721 3.32177 6.95997 5.14087 5.14087C6.95997 3.32177 9.42721 2.2998 11.9998 2.2998C14.5724 2.2998 17.0396 3.32177 18.8587 5.14087C20.6778 6.95997 21.6998 9.42721 21.6998 11.9998ZM20.2998 11.9998C20.2998 9.79851 19.4253 7.68737 17.8688 6.13082C16.3122 4.57427 14.2011 3.6998 11.9998 3.6998C9.79851 3.6998 7.68737 4.57427 6.13082 6.13082C4.57427 7.68737 3.6998 9.79851 3.6998 11.9998C3.6998 14.2011 4.57427 16.3122 6.13082 17.8688C7.68737 19.4253 9.79851 20.2998 11.9998 20.2998C14.2011 20.2998 16.3122 19.4253 17.8688 17.8688C19.4253 16.3122 20.2998 14.2011 20.2998 11.9998Z"
        fill="#858DBD"
      />
    </svg>
  );
};
