import { Header } from '@components/Header';
import { CustomTable } from '@components/Table';
import { LinearProgressLoader } from '@components/spinners/LinearProgressLoader';
import { TSSOProvider } from 'app/types/entities/TSSOProvider';
import { usePageContext } from '@pages/Admin/SSOProviders/providers/PageProvider';
import { PagePagination } from '@components/Pagination';

import { Wrapper } from './styles/Wrapper';
import { CellContent } from './styles/CellContent';

export const Content = () => {
  const { isLoading, pageSize, setPageSize, search, setSearch, ssoProviders, exportCSV } = usePageContext();

  const tableHead = [
    {
      headComponent: () => <td>#</td>,
      render: (data: TSSOProvider, index: number) => <CellContent>{index + 1}</CellContent>,
    },
    {
      headComponent: () => <td>Domain</td>,
      render: (data: TSSOProvider) => <CellContent>{data.domain}</CellContent>,
    },
    {
      headComponent: () => <td>Cognito Identity Provider</td>,
      render: (data: TSSOProvider) => <CellContent>{data.identityProvider}</CellContent>,
    },
  ];

  return (
    <>
      <Header
        title={'SSO Providers'}
        buttonLabel={ssoProviders.data?.length ? 'Export CSV' : undefined}
        buttonOnClick={ssoProviders.data?.length ? () => exportCSV() : undefined}
        setSearch={setSearch}
        search={search}
      />

      <LinearProgressLoader active={isLoading} />
      <Wrapper>
        <CustomTable head={tableHead} data={ssoProviders.data} />
        <PagePagination totalItemsCount={ssoProviders.count ?? 0} pageSize={pageSize} setPageSize={setPageSize} />
      </Wrapper>
    </>
  );
};
