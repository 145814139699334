import { useNavigate } from 'react-router';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';
import { getAmericanTime } from '@helpers/getAmericanTimezone';

import { routes } from '@routes';

import { Header } from '@components/Header';
import { CustomTable } from '@components/Table';
import { Button } from '@components/form-elements/buttons/Button';
import { StatusText } from '@components/Table/StatusText';
import { INVITATION_STATUSES } from '@constants/enums/invitationStatuses';
import { LinearProgressLoader } from '@components/spinners/LinearProgressLoader';
import { TUser } from 'app/types/entities/TUser';
import { TInvitation } from 'app/types/entities/TInvitation';
import { usePageContext } from '@pages/Admin/OrganizationInvitationsListPage/providers/PageProvider';
import { HeaderSwitchWrapper } from '@components/Header/components/HeaderSwitchWrapper';

const CellContent = styled.div`
  margin: 0;
  padding: 20px 0;
`;

const MainContent = styled.main`
  padding: 8px 30px 70px;
  min-height: calc(100vh - 120px);

  td {
    vertical-align: top;
  }
`;

const RolesCell = styled.div`
  /* height: 22px; */
  padding: 6px;
  background: white;
  color: #858dbd;
  font-weight: 500;
  font-size: 12px;
  line-height: 90%;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  margin: 0 10px 10px 0;

  svg {
    cursor: pointer;
  }
`;

export const Content = () => {
  const navigate = useNavigate();
  const { id, isLoading, users, openInviteUserModal } = usePageContext();

  const tableHead = [
    {
      render: (data: TUser) => <CellContent>{getAmericanTime(data.createdAt)}</CellContent>,
      headComponent: () => <td>Created</td>,
    },
    {
      headComponent: () => <td>Email Address</td>,
      render: (data: TUser) => <CellContent>{data.email}</CellContent>,
    },
    {
      headComponent: () => <td>Teams</td>,
      render: (data: TUser) => (
        <CellContent>
          {(data?.Teams || []).map((team) => (
            <RolesCell key={team.id}>{team.name}</RolesCell>
          ))}
        </CellContent>
      ),
    },
    {
      headComponent: () => <td>Status</td>,
      render: (data: TInvitation) => (
        <CellContent>
          <StatusText status={INVITATION_STATUSES[data.status as keyof typeof INVITATION_STATUSES]} />
        </CellContent>
      ),
    },
    {
      headComponent: () => <td>Expiry Date</td>,
      render: (data: TInvitation) => <CellContent>{getAmericanTime(data.expiry)}</CellContent>,
    },
  ];

  return (
    <>
      <Helmet>
        <title>Organization Invitations list - AutoScript Admin</title>
      </Helmet>
      <Header
        leftSideContent={[
          <HeaderSwitchWrapper key="tabSwitcher">
            <Button
              onClick={() => {
                navigate(routes.adminOrganizationUsersList.make(id));
              }}
              className="button"
            >
              Members
            </Button>
            <Button
              onClick={() => {
                navigate(routes.adminOrganizationInvitationsList.make(id));
              }}
              className="button active"
            >
              Invitations
            </Button>
          </HeaderSwitchWrapper>,
        ]}
        title="Invitations"
        buttonLabel={'Invite Member'}
        buttonOnClick={() => openInviteUserModal()}
      />

      <LinearProgressLoader active={isLoading} />
      <MainContent>
        <CustomTable head={tableHead} data={users} />
      </MainContent>
    </>
  );
};
