import styled from 'styled-components';

export const Wrapper = styled.div`
  background: #e4ebf5;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  position: relative;
  padding: 0;
  max-width: 460px;
  text-align: left;
  color: #40608f;

  h2 {
    background: linear-gradient(90deg, #7aabf3 0%, #2765be 100%);
    color: #fff;
    font-size: 26px;
    font-weight: 600;
    line-height: 34px;
    letter-spacing: 0.01em;
    text-align: left;
    padding: 20px 30px;
    border-radius: 5px 5px 0 0;
  }

  .close-icon {
    position: absolute;
    top: 22px;
    right: 30px;
    cursor: pointer;
  }

  .hammer {
    position: absolute;
    top: 30px;
    left: 0;
    z-index: 1;
  }

  .content {
    position: relative;
    padding: 20px 30px 30px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    z-index: 10;
  }

  .description {
    padding: 0 0 25px;
  }

  button {
    display: block;
    width: 130px;
  }
`;
