import styled from 'styled-components';

export const Wrapper = styled.main`
  padding: 20px 30px 50px;
  min-height: calc(100vh - 120px);

  .version {
    text-align: left;
    display: inline-flex;
    width: 100%;
    position: relative;

    font-family: 'General Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #858dbd;
  }

  .variables {
    font-family: 'General Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #858dbd;
  }

  .first-stage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .flex-block {
      display: flex;
      gap: 20px;
      justify-content: space-between;
      align-items: flex-start;
      padding: 0 0 20px;
      width: 100%;

      .head-line {
        display: flex;
        gap: 10px;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .title {
          font-size: 12px;
          font-weight: 600;
          line-height: 18px;
          text-align: left;
          color: #858dbd;
        }

        svg {
          cursor: pointer;

          :hover {
            path {
              stroke: #40608f;
            }
          }
        }
      }

      .left-wrapper,
      .right-wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;

        textarea {
          background: #ffffff;
          min-height: 40vh;
        }
      }
    }
    button {
      width: 125px;
    }
  }

  .second-stage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .flex-block {
      display: flex;
      gap: 20px;
      justify-content: space-between;
      align-items: center;
      padding: 0 10px 10px;
      width: 100%;

      .left-wrapper,
      .right-wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        justify-content: flex-end;
        align-items: center;
        width: 100%;

        .lines {
          font-size: 12px;
          font-weight: 600;
          line-height: 18px;
          text-align: left;
          color: #858dbd;
        }
      }
    }
    button {
      width: 125px;
    }

    .diff {
      min-width: 800px;
      width: 100%;
      background: #ffffff;
      box-shadow: 0 8px 10px rgba(0, 0, 0, 0.05);
      border-radius: 10px;
      position: relative;
      flex: 1 0 auto;
      margin: 0 0 20px;

      table {
        background: transparent;
        * {
          font-family: 'General Sans', sans-serif !important;
          font-size: 16px !important;
          line-height: 24px !important;
        }
      }
      table tbody tr:first-child td:first-child {
        border-radius: 10px 0 0 0;
      }
      table tbody tr:first-child td:last-child {
        border-radius: 0 10px 0 0;
      }
      table tbody tr:last-child td:first-child {
        border-radius: 0 0 0 10px;
      }
      table tbody tr:last-child td:last-child {
        border-radius: 0 0 10px 0;
      }
    }
  }
`;
