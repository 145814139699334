import { useAuthContext } from '@providers/AuthProvider';
import { convertDuration } from '@helpers/convertDuration';

import { Error } from '@components/Error';
import { formatCentsPrice } from '@helpers/formatCentsPrice';
import { RefreshBalance } from '@components/Modal/UnlockJobModal/icons/RefreshBalance';

import { TProps } from './types/TProps';
import { MINIMUM_ACCEPTED_AUDIO_DURATION } from '@components/Modal/PriceModal/constants';
import { StyledSelect } from '@components/Modal/PriceModal/styles/StyledSelect';

export function PriceModalPriceDescription({
  price,
  setDataLoaded,
  balanceIsRefreshing,
  setBalanceIsRefreshing,
  audioDuration,
  model,
  setModel,
  organizationCanUseModel2,
}: TProps) {
  const { updateMe, organization } = useAuthContext();

  const balance = organization?.billingAccount?.currentBalance ?? 0;

  return (
    <div>
      <div className="title">Current balance</div>
      <div className={`title-value ${balanceIsRefreshing ? 'balance-refresh' : ''}`}>
        {formatCentsPrice(balance)}
        <RefreshBalance
          onClick={() => {
            if (balanceIsRefreshing) {
              return;
            }
            setBalanceIsRefreshing(true);

            updateMe()
              .then(() => {
                setBalanceIsRefreshing(false);
                setDataLoaded(true);
              })
              .catch(() => {
                setBalanceIsRefreshing(false);
                setDataLoaded(true);
              });
          }}
        />
      </div>

      {organizationCanUseModel2 ? (
        <>
          <StyledSelect
            options={[
              { value: 'model-1', label: 'Model 1' },
              { value: 'model-2', label: 'Model 2' },
            ]}
            label="Transcription model"
            name="model"
            value={model}
            onChange={(_, value) => setModel(value)}
          />
        </>
      ) : null}

      <p className="description-text">
        <span>After submitting this job you will be charged</span>
      </p>
      <div className="text-end">{formatCentsPrice(price?.price)}</div>

      <div className="dflex">
        <span>Duration:</span>
        {convertDuration(price?.duration ?? 0)}
      </div>

      <div className="dflex">
        <span>Cost per audio hour:</span> ${parseFloat(price?.ASRRate).toFixed(2)}
      </div>

      {audioDuration < MINIMUM_ACCEPTED_AUDIO_DURATION && (
        <Error testid="durationError" error={{ code: 'err.duration' }} />
      )}
    </div>
  );
}
