import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 400px;
  background: #fff;
  position: relative;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.05);
  border-radius: 5px;

  & .buttonWrapper {
    display: flex;
    grid-gap: 10px;
    padding: 10px 30px 30px;
    position: sticky;
    bottom: 0;
  }

  & p {
    text-transform: capitalize;
  }

  & h2 {
    font-family: General Sans;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color:#858DBD;
    }

  .centerText {
      display: flex;
      justify-content: center;
      align-items: center;
    }

  .defaultMethod {
      background: #f1f5fb;
    }

  .topUpText {
      font-family: General Sans;
      font-size: 12px;
      font-weight: 600;
      line-height: 18px;
      text-align: left;
      color: #858DBD;
    }

  .descriptionText {
      font-family: General Sans;
      font-size: 12px;
      font-weight: 400;
      line-height: 15.6px;
      text-align: left;
      color: #575757;
    }

  .inputLabelText {
      font-family: General Sans;
      font-size: 12px;
      font-weight: 500;
      line-height: 12px;
      text-align: left;
      color: #858DBD;
  }

  .note-text {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15.6px;
    color: #575757;
    margin-top: 15px;
    margin-bottom: 10px;
  }

   .error-text {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15.6px;
    margin-top: 15px;
    margin-bottom: 10px;
  }
}
`;
